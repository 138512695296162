<template>
  <div>
    <Card>
      <template #card-body>
        <div class="mt-3">
          <b-button variant="primary-custom" @click="$router.push('/usuarios/adicionar')">Adicionar</b-button>
        </div>
        <b-table class="mt-3" hover :fields="tableFields" :items="data">
          <template #cell(type)="data">
            <span v-if="data.item.type == 'user'">Usuário</span>
            <span v-if="data.item.type == 'admin'">Administrador</span>
          </template>
          <template #cell(active)="data">
            <b-badge v-if="data.item.active == '1'" pill variant="success"
              >Ativo</b-badge
            >
            <b-badge v-if="data.item.active == '0'" pill variant="warning"
              >Inativo</b-badge
            >
          </template>

          <template #cell(id)="data">
            <b-dropdown
              class="p-0 m-0 d-flex"
              size="md"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <fa-icon size="1x" icon="bars" />
              </template>
              <b-dropdown-item title="Detalhes" @click="show_details(data)">Detalhes</b-dropdown-item>
              <b-dropdown-item title="Detalhes" @click="$router.push(`/usuarios/${data.item.id}/editar`)">Editar</b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </template>
    </Card>
  </div>
</template>

<script>
import tableFields from "../schema/TableListFields";
export default {
  props: ["data"],
  data() {
    return {
      tableFields,
    };
  }
};
</script>

<style>
</style>